<template>
  <div data-vue-component-name="PrintSamples">
    <!-- background -->
    <img
      id="background"
      width="1912"
      height="1000"
      src="backgrounds/Print Samples.png"
      alt="page background image"
      draggable="false"
    >

    <ButtonBack @click="goBack" />

    <!-- navigation -->
    <transition name="fade">
      <PrintSamplesNavigation
        v-if="isNull(activeCardIndex)"
        :key="segments.length"
        :items="segments"
        :active-item-index="activeSegmentIndex"
        @update-index="toggleActiveSegmentItem"
      />
    </transition>

    <!-- print sample preview -->
    <img
      id="preview"
      src="@/assets/images/previews/print-samples-2.png"
      alt="print samples"
      v-bind="props.Preview"
    >

    <!-- cards -->
    <transition :name="transitionName">
      <PrintSamplesCards
        v-if="!activeCard"
        :key="filteredPrintSamplesList.length"
        v-bind="props.PrintSamplesCards"
        :cards="filteredPrintSamplesList"
        @onCardClick="toggleActiveCardIndex"
      />
    </transition>

    <!-- single view -->
    <transition name="fade-singleView">
      <PrintSamplesSingleView
        v-if="activeCard"
        :card="activeCard"
        @on-close="toggleActiveCardIndex(null)"
        @on-request-sample="toggleForm(true)"
        @on-download-flyer="onDownloadFlyer"
      />
    </transition>

    <!-- labels -->
    <transition name="fade">
      <div
        v-if="isNull(activeCardIndex)"
        class="background-labels"
      >
        <LabelPrint v-bind="props.LabelPrint" />
        <LabelSamples v-bind="props.LabelSamples" />
      </div>
    </transition>

    <!-- bottom hint -->
    <transition name="fade">
      <span v-if="isNull(activeCardIndex)">
        Click to enlarge and learn more about the printed sample.
      </span>
    </transition>

    <!-- form -->
    <transition name="fade">
      <AppWrapper
        v-if="isFormOpened"
        bg-color="rgba(0, 0, 0, 0.5)"
        blur="16"
      >
        <AppForm
          :title="requestSampleForm.formTitle"
          :description="requestSampleForm.formText"
          :inputs="requestSampleForm.fields"
          v-click-outside="() => toggleForm(false)"
          @on-submit="sendForm"
        />
      </AppWrapper>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  ButtonBack,
  AppWrapper,
  AppForm,
  PrintSamplesNavigation,
} from '@xsys2/components';
import { animationController } from '@/modules/printSamples';
import { isNull, downloadFile } from '@xsys2/functions';

const { props } = animationController();
const store = useStore();
const router = useRouter();
store.dispatch('printSamples/getData');

// navigation
const segments = computed(() => store.getters['printSamples/getSegments']);
const activeSegmentIndex = ref(0);
const toggleActiveSegmentItem = value => (activeSegmentIndex.value = value);
const activeSegment = computed(() => segments.value[activeSegmentIndex.value]);

// cards
const transitionName = ref('fade');
// FIXME
setTimeout(() => {
  transitionName.value = 'fade-cards';
}, 1000);
const printSamplesList = computed(
  () => store.state.printSamples.printSamplesList,
);
const filteredPrintSamplesList = computed(() => {
  const filteredList = printSamplesList.value
    .filter(card => card.segments.includes(activeSegment.value));

  return filteredList.length
    ? filteredList
    : printSamplesList.value;
});

const goBack = () => {
  activeCardIndex.value = null;
  router.go(-1);
};

// active card
const activeCardIndex = ref(null);
const toggleActiveCardIndex = index => (activeCardIndex.value = index);
const activeCard = computed(
  () => store.state.printSamples.printSamplesList[activeCardIndex.value],
);

// show/hide service wrapper
watch(activeCardIndex, (index) => {
  const serviceWrapperProps = {
    bgColor: 'rgba(0, 0, 0, 0.5)',
    style: {
      zIndex: 1,
      transition: 'all 1.5s ease-in-out',
    },
  };

  store.commit(
    'ui/SET_SERVICE_WRAPPER_PROPS',
    isNull(index)
      ? null
      : serviceWrapperProps,
  );
});

// form
const isFormOpened = ref(false);
const toggleForm = value => (isFormOpened.value = value);

const requestSampleForm = computed(
  () => store.state.printSamples.requestSampleForm,
);

const onDownloadFlyer = () => downloadFile(activeCard.value.downloadUrl);

const sendForm = async (values) => {
  const result = await store.dispatch(
    'sendForm',
    {
      subject: `Print Samples - ${activeCard.value.title}`,
      message: values,
    },
  );

  if (result) {
    toggleForm(false);
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0 !important;
}

.fade-cards-enter-active,
.fade-cards-leave-active {
  transition: all 1.5s ease-in-out !important;
}

.fade-cards-enter-from,
.fade-cards-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) !important;
}

.fade-singleView-enter-active,
.fade-singleView-leave-active {
  transition: all 1.5s ease-in-out;
}

.fade-singleView-enter-from,
.fade-singleView-leave-to {
  opacity: 0;
  margin-top: 40vw;
}

[data-vue-component-name="PrintSamples"] {
  position: relative;
  height: 100vh;
  width: 100vw;

  img#background {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }

  [data-vue-component-name="ButtonBack"] {
    position: absolute;
    z-index: 1;
    top: 1.667vw;
    left: 1.667vw;
  }

  [data-vue-component-name="PrintSamplesNavigation"] {
    position: absolute;
    z-index: 1;
    top: 2vw;
    left: 50%;
    transform: translateX(-50%);
  }

  img#preview {
    position: absolute;
    z-index: 1;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
  }

  [data-vue-component-name="PrintSamplesCards"] {
    position: absolute;
    z-index: 1;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  [data-vue-component-name="PrintSamplesSingleView"] {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 62.760vw;
  }

  .background-labels {
    [data-vue-component-name="LabelPrint"],
    [data-vue-component-name="LabelSamples"] {
      position: absolute;
      z-index: 0;
      left: 50%;
    }

    [data-vue-component-name="LabelPrint"] {
      transform: translateX(-49%);
    }

    [data-vue-component-name="LabelSamples"] {
      transform: translateX(-50%);
    }
  }

  span {
    position: absolute;
    bottom: 3vw;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.833vw;
    line-height: 1.146vw;
    color: #fff;
    opacity: 0.5;
  }

  [data-vue-component-name="AppWrapper"] {
    @include absolute-full;
    @include flex-center;
    z-index: 1;

    @include firefox {
      background: rgba(0, 0, 0, 0.75) !important;
    }

    [data-vue-component-name="AppForm"] {
      background-color: rgba(255, 255, 255, 0.2);
      padding: 2.500vw;

      @include firefox {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ), #000000 !important;
      }
    }
  }
}
</style>
